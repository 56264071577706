<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div class="title">{{ $tc('title.filtro', 2) }}</div>
        <v-spacer></v-spacer>
         <v-icon v-show="mostrarFiltros"
        @click="fixaFiltros"
        :class="filtrosFixosClass"
        >
        fa-thumbtack
      </v-icon>
        <v-icon @click="toggleMostrarFiltros">filter_list</v-icon>
      </v-card-title>
      <v-container fluid grid-list-md v-show="mostrarFiltros">
        <metadados-container-layout
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :formulario-filtros="true"
          :ignora-obrigatorios="false"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtros"
          ref="container">
        </metadados-container-layout>
      </v-container>
      <v-card-actions v-show="mostrarFiltros">
        <v-spacer></v-spacer>
        <v-btn @click="resetaFiltros"
          color="accent"
        >{{$t('label.limpar_filtros')}}</v-btn>
        <v-btn @click="filtrar"
          color="primary"
        >{{$t('label.filtrar')}}</v-btn>
      </v-card-actions>
    </v-card>

   <v-card class="mb-4">
    <v-row justify="start">
     <v-col class="pl-8" cols="12" sm="8" md="8" align-self="center">

        <span class="title">{{ $t('title.kpis_sustentabilidade', 1) }} </span>

        <v-tooltip bottom v-if="canAccessCRUD && selected.length">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_ativar_inativar" icon class="mx-0" v-on="on" @click="concluir()">
                <v-icon>done_all</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.concluir') }}</span>
          </v-tooltip>
      </v-col>
    </v-row>

    <v-data-table
      id="tabela_dados_projeto"
      v-model="selected"
      :headers="headers"
      :items="kpis"
      :show-select="podeSelecionarTodos"
      class="custom-pagination-left"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }"
      >
      <template v-slot:item="{ item, isSelected, select  }">
        <tr :key="item.id">
          <td v-if="canAccessCRUD && podeSelecionarTodos">
            <v-simple-checkbox :value="canAccessCRUD && isSelected"  @input="select($event)"></v-simple-checkbox>
          </td>
          <td class="d-flex justify-center align-center flex-row px-0 AL">
            <v-simple-checkbox
            v-if="canAccessCRUD && !podeSelecionarTodos && itemEmExecucao(item)"
            :value="canAccessCRUD && isSelected"  @input="select($event)">
            </v-simple-checkbox>
            <v-tooltip bottom v-if="canAccessCRUD && itemEmExecucao(item)" >
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click.stop="editar(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.editar') }}</span>
            </v-tooltip>
            <v-tooltip bottom  v-if="!canAccessCRUD || !itemEmExecucao(item)">
              <template v-slot:activator="{ on }">
                <v-btn icon class="mx-0" v-on="on" @click="ver(item)">
                  <v-icon>visibility</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('label.visualizar') }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.nomDoacao}}</td>
          <td>{{ item.tipo === 'PROJETO' ? $t('label.projeto') :$t('label.instituicao') }}</td>
          <td>{{ item.projetoInstituicao}}</td>
          <td>{{ getStatus(item.status) }}</td>
        </tr>
      </template>
    </v-data-table>
   </v-card>
    <confirm
        ref="confirmDialog"
        :message="getMessageConcluir()"
        @agree="executarConcluir">
    </confirm>
  </div>
</template>
<script>

import MetadadosContainerLayout from '../../produto/shared-components/metadados/MetadadosContainerLayout';
import Confirm from '../../produto/shared-components/vuetify/dialog/Confirm';
import { skipLoading } from '../../produto/common/functions/loading';
import KpisListFiltros from './KpisListFiltros';
import { buscarKpis } from '../../common/resources/akzo-kpis-sustentabilidade';

export default {
  name: 'KpisListDados',
  mixins: [
    KpisListFiltros,
  ],
  props: {
    canAccessCRUD: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      projetoResources: this.$api.akzoAcaoProjeto(this.$resource),
      resourceKpis: this.$api.akzoKpis(this.$resource),

      kpis: [],
      selected: [],

      esperar: false,
      pagination: {},
      totalPage: 0,
      filtros: {},
      mostrarFiltros: false,
      filtrosAbertos: false,
      filtrosFixados: false,
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      ordenacaoCampos: [
        'kpis_cod',
        'kpis_nome_doacao',
        'status',
        'kpis_tipo',
        'projeto',
        'instituicao',
        'cod_acao',
        'tipo_acao',
      ],

      headers: [
        {
          text: '', value: 'acoes', sortable: false, width: '100', align: 'center',
        },
        {
          text: this.$tc('label.kpis_cod', 1), value: 'id', sortable: true,
        },
        {
          text: this.$tc('label.kpis_nome_doacao', 1), value: 'nom_doacao', sortable: true,
        },
        {
          text: this.$tc('label.kpis_tipo', 1), value: 'tipo', sortable: true,
        },
        {
          text: this.$tc('label.kpis_projeto_instituicao', 1), value: 'projeto_instituicao', sortable: false,
        },
        { text: this.$tc('label.status', 1), value: 'status', sortable: true },
      ],
    };
  },
  components: {
    MetadadosContainerLayout,
    Confirm,
  },
  computed: {
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
      };
    },
    podeSelecionarTodos() {
      return this.kpis.filter((value) => value.status !== 'EM_EXECUCAO').length < 1;
    },
    isStatusCancelado(item) {
      return item && item.status === 'CANCELADO';
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  methods: {
    itemEmExecucao(item) {
      return item && item.status && item.status === 'EM_EXECUCAO';
    },
    getStatus(value) {
      switch (value) {
        case 'EM_EXECUCAO':
          return this.$tc('label.execucao', 1);
        case 'CANCELADO':
          return this.$tc('label.cancelado', 1);
        case 'CONCLUIDO':
          return this.$tc('label.concluido', 1);
        default:
          return '';
      }
    },
    selectAll(values) {
      if (values && values.items && values.items.length > 0) {
        this.selected = values.items.filter((value) => value.status === 'EM_EXECUCAO');
      }
    },

    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        this.buscar();
      }, 5E2);
    },
    buscar() {
      const params = {
        ...this.$refs.container.getValoresCamposPadrao(),
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };
      buscarKpis(params, this.$resource).then((response) => {
        this.kpis = response.data.resposta;
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.pararEsperar();
        this.selected = [];
      }, (err) => {
        this.pararEsperar();
        this.$error(this, err);
      });
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    toggleAll() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.kpis.slice();
      }
    },
    concluir() {
      this.$refs.confirmDialog.open();
    },
    getMessageConcluir() {
      const ids = this.selected.filter((val) => val.status === 'EM_EXECUCAO').map((el) => el.id).join(', ');
      const msg = this.$tc('message.kpis_concluir_massa', 1);
      return msg.concat(`  ${ids}`);
    },
    executarConcluir() {
      this.resourceKpis.concluirMassa(this.selected)
        .then(() => {
          this.filtrar();
          this.$toast(this.$t('message.atualizado_confira_tabela'));
        }, (err) => {
          this.$error(this, err);
        });
    },
    editar(item) {
      this.$router.push({ name: 'editarKpis', params: { id: item.id } });
    },
    ver(item) {
      this.$router.push({ name: 'verKpis', params: { id: item.id } });
    },
    resetaFiltros() {
      this.filtros = {};
      this.filtrar();
    },
    toggleMostrarFiltros() {
      this.mostrarFiltros = !this.mostrarFiltros;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'kpis-akzo';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'kpis-akzo';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    carregarWorkspaceFiltros() {
      const entidade = 'kpis-akzo';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtros = this.workspace.filtros || {};

          setTimeout(() => this.$refs.container.refresh());
          this.filtrar();
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    this.carregarWorkspaceFiltros();
  },
};
</script>
